import { Footer, NavBar } from '@gasbuddy/react-consumer-components';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { version } from '../../../../package.json';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useConfig from '../../../lib/hooks/useConfig';
import useGeoIpInfo from '../../../lib/hooks/useGeoIpInfo';
import usePageContext from '../../../lib/hooks/usePageContext';
import useProfile from '../../../lib/hooks/useProfile';
import { truncateForDisplay } from '../../../lib/utils';
import canShowPrivacyOptions from '../../../lib/utils/canShowPrivacyOptions';
import { ANALYTICS_EVENTS, COUNTRIES_WITH_PAY } from '../../constants';

export default function PageLayout({ children }) {
  const analytics = useAnalytics();
  const history = useHistory();
  const { country } = useGeoIpInfo();
  const { navKey: activeNavKey, pageName } = usePageContext();
  const { enrollHost, payHost, trackerHost } = useConfig();
  const { isLoggedIn, memberName: username, profileImageUrl: avatar } = useProfile();
  const isLoggedOut = !isLoggedIn;

  const handleNavbarItemClick = useCallback((e) => {
    const { currentTarget, target } = e;
    const linkName = target.innerText;

    // menu item is in a span. Current target gives us the element that actually has the event
    const linkTarget = currentTarget.target;
    const href = currentTarget.attributes.href.value;

    if (linkName === 'Vehicle Recalls') {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.OPEN_RECALL_SEARCH_PAGE,
        attributes: {
          Page: linkName,
          source: 'navbar',
        },
      });
    } else {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.MAIN_MENU_ITEM_CLICK,
        attributes: {
          name: linkName,
          href,
          'Page Type': pageName,
        },
      });
    }

    const isRelative = href.charAt(0) === '/' && href.charAt(1) !== '/';
    const opensNewTab = linkTarget === '_blank';
    const shouldRedirect = [
      '/login',
      '/logout',
      '/signup',
      '/password/change',
    ].includes(href) || (isLoggedOut && href.startsWith('/account/'));

    if (isRelative && !opensNewTab && !shouldRedirect) {
      e.preventDefault();
      history.push(href);
    }
  }, [analytics, history, pageName, isLoggedOut]);

  const generateAccountObject = useMemo(() => {
    if (!username) {
      return undefined;
    }

    return {
      username: truncateForDisplay(username),
      avatar,
    };
  }, [avatar, username]);

  const handleFooterItemClick = useCallback((e) => {
    const { currentTarget, target } = e;
    const linkName = target.innerText;
    const href = currentTarget.attributes.href.value;

    analytics.tagEvent({
      name: ANALYTICS_EVENTS.FOOTER_ITEM_CLICK,
      attributes: {
        name: linkName,
        href,
        'Page Type': pageName,
      },
    });
  }, [analytics, pageName]);

  const applyCcpa = canShowPrivacyOptions(country);

  const mainMenuItems = [...NavBar.defaultProps.mainLinkGroups];
  let footerSitemap = [...Footer.defaultProps.sitemap];

  const showPayLink = country && COUNTRIES_WITH_PAY.includes(country.toUpperCase());

  if (!showPayLink) {
    mainMenuItems.splice(2, 1);
    const footerFeatures = footerSitemap.find(group => group.groupName === 'Features');
    if (footerFeatures) {
      footerFeatures.siteLinks = footerFeatures.siteLinks.filter(link => !link.href.match(/.*(pay|credit|gasback)/));
    }
    footerSitemap = [
      footerFeatures,
      ...footerSitemap.filter(group => group.groupName !== 'Features'),
    ];
  }

  return (
    <Fragment>
      <NavBar
        account={generateAccountObject}
        activeHrefKey={activeNavKey}
        consumerHost="" // Make consumer-web URLs relative
        enrollHost={enrollHost} // Make payment-web URLs environment-friendly
        identityHost="" // Make identity-web URLs relative
        mainLinkGroups={mainMenuItems}
        onMenuItemClick={handleNavbarItemClick}
        payHost={payHost} // Make payment-web URLs environment-friendly
        rootContainerId="root"
        trackerHost={trackerHost} // Make tracker-web URLs environment-friendly
      />
      {children}
      <Footer
        applyCcpa={applyCcpa}
        consumerHost="" // Make consumer-web URLs relative
        enrollHost={enrollHost} // Make payment-web URLs environment-friendly
        identityHost="" // Make identity-web URLs relative
        onItemClick={handleFooterItemClick}
        payHost={payHost} // Make payment-web URLs environment-friendly
        sitemap={footerSitemap}
        trackerHost={trackerHost} // Make tracker-web URLs environment-friendly
        version={version}
      />
    </Fragment>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node,
};

PageLayout.defaultProps = {
  children: undefined,
};
